
export const Courses =[

  {
    "id":1,
    "course_name":'AWS & DevOps Certification - Premium',
    "course_img":"https://ik.imagekit.io/microdegree/Main%20Website%20Videos/aws3_HBtJdD14S.png?updatedAt=1716118967038",
    // "course_link":"https://tally.so/r/wvrq48",
    "course_link":'https://trainings.microdegree.work/aws-devops',
    "course_desc":"Master AWS and DevOps with our premium course, projected to be in highest demand for jobs in 2024. Boost your career with expert-led instruction and practical skills in Cloud Computing and DevOps",
    "course_category":'Premium',
    // "course_price":'Coming Soon',
    "course_price":'22,500',
    "url":"https://courses.microdegree.work/courses/aws-developer-certification-live",
    "course_status":['Working Professionals'],
    course_details:["60 Hrs of LIVE Online Training",
    "Concepts Explained in Kannada",
    "Completion Certificate",
    "Build Interactive Projects",
    "Doubt Clarification",
    "Interview Preparation",
    "Career Assistance",
  ],
},

{
  id:2,
  "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/awsdevops?updatedAt=1719032181424",
  "course_category":'Standard',
  "course_name":'AWS & DevOps Certification - Standard',
  "course_desc":"Master AWS & DevOps with insights from cloud industry experts, paving the way to become a skilled Computing Professional ready for the highest-paying roles in 2024",
  "course_link":'https://trainings.microdegree.work/aws-devops',
  // "course_link":'https://microdegree.learnyst.com/learn/Power-Bi',
  "course_status":['Working Professionals'],
  "course_price":'12,500',
  "url":"",
  course_details:["65 Hrs of LIVE Online Training",
  "Concepts Explained in Kannada",
  "Completion Certificate",
  "Build Interactive Projects",
  "Doubt Clarification",
  "Interview Preparation",
  "Career Assistance",
],
},

{
  id:3,
  "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/Pythonfullstack_raspNe9i4K.png?updatedAt=1716181481686",
  "course_category":'Programming',
  "course_name":'Python Full-Stack Certification',
  "course_desc":"Elevate your career with our Python Full-Stack certification, mastering both backend and frontend development for comprehensive expertise in building dynamic web applications.",
  "course_link":'https://trainings.microdegree.work/python-fullstack',
  //"course_link":'https://trainings.microdegree.work/learn/Python-',
  "course_status":['Freshers'],
  "course_price":'9,500',
  "url":"https://trainings.microdegree.work/python-fullstack",
  course_details:["Build 5 Real-World Projects",
  "Daily Live Classes",
  "Learn Gen AI, ChatGPT, Automation ",
  "Profile Building ",
  "Trained by Industry Experts",
  "Telegram Support - Doubt Clarification",
  "Interview Preparation - Hacks",
],
},
//   {
//       "id":5,
//       "course_name":'Aws Devops Pre-Recorded',
//       "course_img":"https://ik.imagekit.io/microdegree/Course_Images/AWSDevOps11_bkQbtt1aKi.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1661163836101",
//       "course_link":"https://courses.microdegree.work/bundles/aws-devops",
//       "course_desc":"Learn AWS & DevOps with our Industry Experts and Become a Solid Cloud Professional in 60 Hours",
//       "course_category":'programming',
//       "course_price":'6,500',
//       "course_status":['live'],
//       course_details:["60 Hrs of LIVE Online Training",
//       "Concepts Explained in Kannada",
//       "Completion Certificate",
//       "Build Interactive Projects",
//       "Doubt Clarification",
//       "Interview Preparation",
//       "Career Assistance",
//     ],
//   },
//   {
//     "id":6,
//     "course_name":'React js',
//     "course_img":"https://ik.imagekit.io/microdegree/Course_Images/ReactJS_AXgaxluRTp6.png?ik-sdk-version=javascript-1.4.3&updatedAt=1661163836535",
//     "course_link":"https://courses.microdegree.work/courses/react",
//     "course_desc":"Learn React from scratch, build real-world project and get ready to be a solid Frontend Developer",
//     "course_category":'programming',
//     "course_price":'949',
//     "course_status":['live'],
//     course_details:["60 Hrs of LIVE Online Training",
//     "Concepts Explained in Kannada",
//     "Completion Certificate",
//     "Build Interactive Projects",
//     "Doubt Clarification",
//     "Interview Preparation",
//     "Career Assistance",
//   ],
// },
// {
//   "id":7,
//   "course_name":'Python Certification',
//   "course_img":"https://ik.imagekit.io/microdegree/Course_Images/Python_vWkoh4U97Dj.png?ik-sdk-version=javascript-1.4.3&updatedAt=1661163836461",
//   "course_link":"https://courses.microdegree.work/courses/python",
//   "course_desc":"Learn Python from scratch and build interactve projects. Kick-start your journey into the world of Data Science and Machine Learning here.",
//   "course_category":'programming',
//   "course_price":'949',
//   "course_status":['live'],
//   course_details:["60 Hrs of LIVE Online Training",
//   "Concepts Explained in Kannada",
//   "Completion Certificate",
//   "Build Interactive Projects",
//   "Doubt Clarification",
//   "Interview Preparation",
//   "Career Assistance",
// ],
// },
// {
//   "id":8,
//   "course_name":'MySQL Certification',
//   "course_img":"https://ik.imagekit.io/microdegree/Course_Images/MySQL_o0JnXmAg1KT.png?ik-sdk-version=javascript-1.4.3&updatedAt=1661163836321",
//   "course_link":"https://courses.microdegree.work/courses/mysql",
//   "course_desc":"Learn Python from scratch and build interactve projects. Kick-start your journey into the world of Data Science and Machine Learning here.",
//   "course_category":'programming',
//   "course_price":'949',
//   "course_status":['live'],
//   course_details:["60 Hrs of LIVE Online Training",
//   "Concepts Explained in Kannada",
//   "Completion Certificate",
//   "Build Interactive Projects",
//   "Doubt Clarification",
//   "Interview Preparation",
//   "Career Assistance",
// ],
// },

]