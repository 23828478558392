import React from 'react'
import { useEffect } from "react";
import { useState } from 'react';
import CourseCard from "./CourseCard";
function PopularCourses() {


    // json starts from here 

    const Courses = [
        // {
        //   id:1,
        //   "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/aws1_AzDjnIngk.png?updatedAt=1716182351385",
        //   "course_category":'Cloud Computing',
        //   "course_name":'AWS & DevOps Certification',
        //   "course_desc":'Master AWS & DevOps with insights from cloud industry experts, paving the way to become a skilled Computing Professional ready for the highest-paying roles in 2024',
        //   "course_link":'https://courses.microdegree.work/courses/aws-developer-certification-live',
        //   "course_status":['Working Professionals'],
        //   "course_price":'22,500',
        //   course_details:["60 Hrs of LIVE Online Training",
        //   "Concepts Explained in Kannada",
        //   "Completion Certificate",
        //   "Build Interactive Projects",
        //   "Doubt Clarification",
        //   "Interview Preparation",
        //   "Career Assistance",
        // ],
        // },
        {
          id:1,
          "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/awsdevops?updatedAt=1719032181424",
          "course_category":'Standard',
          "course_name":'AWS & DevOps Certification',
          "course_desc":"Master AWS & DevOps with insights from cloud industry experts, paving the way to become a skilled Computing Professional ready for the highest-paying roles in 2024",
          "course_link":'https://www.microdegree.work/Awsdevops',
          "course_status":['Working Professionals'],
          "course_price":'12,500',
          "url":"",
          course_details:["65 Hrs of LIVE Online Training",
          "Concepts Explained in Kannada",
          "Completion Certificate",
          "Build Interactive Projects",
          "Doubt Clarification",
          "Interview Preparation",
          "Career Assistance",
        ],
        },
        {
          "id":2,
          "course_name":'AWS & DevOps Certification',
          "course_img":"https://ik.imagekit.io/microdegree/Main%20Website%20Videos/awsdev?updatedAt=1719032161154",
          // "course_link":"https://tally.so/r/wvrq48",
          "course_link":'https://www.microdegree.work/Awsdevops',
          "course_desc":"Master AWS and DevOps with our premium course, projected to be in highest demand for jobs in 2024. Boost your career with expert-led instruction and practical skills in Cloud Computing and DevOps",
          "course_category":'Premium',
          // "course_price":'Coming Soon',
          "course_price":'22,500',
          "url":"https://www.microdegree.work/Awsdevops",
          "course_status":['Working Professionals'],
          course_details:["60 Hrs of LIVE Online Training",
          "Concepts Explained in Kannada",
          "Completion Certificate",
          "Build Interactive Projects",
          "Doubt Clarification",
          "Interview Preparation",
          "Career Assistance",
        ],
      },
      {
          id:1,
          "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/Pythonfullstack_raspNe9i4K.png?updatedAt=1716181481686",
          "course_category":'Programming',
          "course_name":'Python Full-Stack Certification',
          "course_desc":"Elevate your career with our Python Full-Stack certification, mastering both backend and frontend development for comprehensive expertise in building dynamic web applications.",
          "course_link":'https://trainings.microdegree.work/python-fullstack',
          //"course_link":'https://trainings.microdegree.work/learn/Python-',
          "course_status":['Freshers'],
          "course_price":'12,500',
          "url":"https://www.microdegree.work/pythonfullstack",
          course_details:["Build 5 Real-World Projects",
          "Daily Live Classes",
          "Learn Gen AI, ChatGPT, Automation ",
          "Profile Building ",
          "Trained by Industry Experts",
          "Telegram Support - Doubt Clarification",
          "Interview Preparation - Hacks",
        ],
        },
        {
          id:1,
          "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/webdev?updatedAt=1719032102003",
          "course_category":'Programming',
          "course_name":'Web Development Certification',
          "course_desc":"Become a pro in Web Development with our premium course, a field set to have lots of new jobs in 2024. Boost your career with expert-led lessons and hands-on skills in modern web technologies",
          "course_link":'https://microdegree.thinkific.com/courses/web-development',
          "course_status":['Freshers'],
          "course_price":'949',
          course_details:["Course starts from complete Basics",
          "No prior coding knowledge required",
          "Get doubts cleared instantly ",
          "Quizzes & Mini Projects ",
          "Unlimited access",
          "Interview Preparation",
          "Career Assistance",
        ],
        },
        {
          id:2,
          "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/dataanalytics?updatedAt=1719032133653",
          "course_category":'Data Science',
          "course_name":'Data Analytics Certification',
          "course_desc": "Become proficient in Data Analytics with experts guiding you. This course covers everything from the basics of Python to advanced concepts, including essential tools like Pandas and NumPy Acquire practical skills and knowledge that make a significant difference",
          "course_status":['Freshers'],
          "course_price":'1,500',
          // "course_link":'https://courses.microdegree.work/courses/mysql',
          "course_link":'https://microdegree.thinkific.com/courses/data-analytics',
          course_details:["5 Hours of Course Content",
          "No prior coding knowledge required",
          "Get doubts cleared instantly ",
          "Quizzes & Mini Projects ",
          "Unlimited access",
          "Telegram Developer Community",
          "Career Assistance",
        ],
        },
        {
          "id":3,
          "course_name":'MicroDegree Pro Certification',
          "course_img":"https://ik.imagekit.io/microdegree/Main%20Website%20Videos/microdegreepro?updatedAt=1719032227151",
          // "course_link":"https://courses.microdegree.work/courses/full-stack-web-development-certification",
          "course_link":"https://microdegree.thinkific.com/courses/microdegree-pro",
          "course_desc":"Advance your skills with our Microdegree Pro Course, combining multiple top-rated courses in one. Gain expertise in various fields with expert-led lessons and hands-on projects to boost your career",
          "course_category":'Programming',
          "course_status":['Freshers'],
          "course_price":'2,500',
          course_details:["60 Hrs of LIVE Online Training",
          "Concepts Explained in Kannada",
          "Completion Certificate",
          "Build Interactive Projects",
          "Doubt Clarification",
          "Interview Preparation",
          "Career Assistance",
        ],},
        {
          id:1,
          "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/Pythonfullstack_raspNe9i4K.png?updatedAt=1716181481686",
          "course_category":'Programming',
          "course_name":'Python Full-Stack Certification',
          "course_desc":"Elevate your career with our Python Full-Stack certification, mastering both backend and frontend development for comprehensive expertise in building dynamic web applications.",
          "course_link":'https://www.microdegree.work/pythonfullstack',
          //"course_link":'https://trainings.microdegree.work/learn/Python-',
          "course_status":['Students'],
          "course_price":'12,500',
          course_details:["Build 5 Real-World Projects",
          "Daily Live Classes",
          "Learn Gen AI, ChatGPT, Automation ",
          "Profile Building ",
          "Trained by Industry Experts",
          "Telegram Support - Doubt Clarification",
          "Interview Preparation - Hacks",
        ],
        },
       
        {
          id:1,
          "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/python?updatedAt=1719032246422",
          "course_category":'Data Science',
          "course_name":'Python Certification',
          "course_desc":"Become a Python pro with expert guidance. Boost your career and explore new possibilities with a Python certification. Learn practical skills that make you stand out in the programming world",
          "course_link":'https://courses.microdegree.work/courses/python',
          //"course_link":'https://trainings.microdegree.work/learn/Python-',
          "course_status":['Students'],
          "course_price":'949',
          course_details:["Course starts from complete Basics",
          "No prior coding knowledge required",
          "Get doubts cleared instantly ",
          "Quizzes & Mini Projects ",
          "Unlimited access",
          "Interview Preparation",
          "Career Assistance",
        ],
        },
        {
          id:2,
          "course_img": "https://ik.imagekit.io/microdegree/Main%20Website%20Videos/mysql?updatedAt=1719032200831",
          "course_category":'DataBase',
          "course_name":'MySQL Certification',
          "course_desc": "Achieve mastery in MySQL through focused training, gaining proficiency in database management. Acquire the skills needed to excel in MySQL certification, positioning yourself as a capable professional in database administration and management",
          "course_status":['Students'],
          "course_price":'949',
          "course_link":'https://courses.microdegree.work/courses/mysql',
          course_details:["5 Hours of Course Content",
          "No prior coding knowledge required",
          "Get doubts cleared instantly ",
          "Quizzes & Mini Projects ",
          "Unlimited access",
          "Telegram Developer Community",
          "Career Assistance",
        ],
        },
        {
          "id":3,
          "course_name":'Javascript Certification',
          "course_img":"https://ik.imagekit.io/microdegree/Main%20Website%20Videos/javascript?updatedAt=1719032265822",
          // "course_link":"https://courses.microdegree.work/courses/full-stack-web-development-certification",
          "course_link":"https://courses.microdegree.work/courses/JavaScriptMasterClass",
          "course_desc":"Become a JavaScript expert with our easy-to-follow course, crafted to match the rising need for developers in 2024. Level up your career with practical learning and expert advice in modern web coding",
          "course_category":'Programming',
          "course_status":['Students'],
          "course_price":'949',
          course_details:["60 Hrs of LIVE Online Training",
          "Concepts Explained in Kannada",
          "Completion Certificate",
          "Build Interactive Projects",
          "Doubt Clarification",
          "Interview Preparation",
          "Career Assistance",
        ],},
  //       {
  //         "id":3,
  //         "course_name":'MicroDegree Pro Bundle',
  //         "course_img":require('../assets/Course Images/Python11.webp'),
  //         "course_link":"https://courses.microdegree.work/courses/full-stack-web-development-certification",
  //         "course_desc":"Start with Basic HTML untill advanced Server Side Concepts with NodeJs & MongoDB to become a Expert MERN Stack Developer",
  //         "course_category":'Programming',
  //         "course_status":['Freshers'],
  //         course_details:["60 Hrs of LIVE Online Training",
  //         "Concepts Explained in Kannada",
  //         "Completion Certificate",
  //         "Build Interactive Projects",
  //         "Doubt Clarification",
  //         "Interview Preparation",
  //         "Career Assistance",
  //       ],},
        
  //       {
  //         "id":3,
  //         "course_name":'Full Stack Web Development',
  //         "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/webDev11.webp?updatedAt=1681883678095",
  //         "course_link":"https://courses.microdegree.work/courses/full-stack-web-development-certification",
  //         "course_desc":"Start with Basic HTML untill advanced Server Side Concepts with NodeJs & MongoDB to become a Expert MERN Stack Developer",
  //         "course_category":'Programming',
  //         "course_status":['weekend live'],
  //         course_details:["60 Hrs of LIVE Online Training",
  //         "Concepts Explained in Kannada",
  //         "Completion Certificate",
  //         "Build Interactive Projects",
  //         "Doubt Clarification",
  //         "Interview Preparation",
  //         "Career Assistance",
  //       ],},

       

  //       {
  //         "id":6,
  //         "course_name":'Automation Testing - Java',
  //         "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/Automation_java.png?updatedAt=1681883792202",
  //         "course_link":"https://tally.so/r/3ND8Lb",
  //         "course_desc":"Master Automation Testing and learn most in-demand skills like Core Java, Selenium & Automation testing to become a solid QA Professional",
  //         "course_category":'Programming',
  //         "course_price":'Coming Soon',
  //         "course_status":['daily live'],
  //         course_details:["60 Hrs of LIVE Online Training",
  //         "Concepts Explained in Kannada",
  //         "Completion Certificate",
  //         "Build Interactive Projects",
  //         "Doubt Clarification",
  //         "Interview Preparation",
  //         "Career Assistance",
  //       ],
  //     },
  //     {
  //       "id":7,
  //       "course_name":'ReactJS',
  //       "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/ReactJS.png?updatedAt=1681883830418",
  //       "course_link":"https://courses.microdegree.work/courses/react",
  //       "course_desc":"Learn React from scratch, build real-world project and get ready to be a solid Frontend Developer",
  //       "course_category":'Programming',
  //       "course_status":['recorded'],
  //       course_details:["60 Hrs of LIVE Online Training",
  //       "Concepts Explained in Kannada",
  //       "Completion Certificate",
  //       "Build Interactive Projects",
  //       "Doubt Clarification",
  //       "Interview Preparation",
  //       "Career Assistance",
  //     ],
  //   },
  //   {
  //     "id":8,
  //     "course_name":'Data Science Weekend Live',
  //     "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/FullStackData11.webp?updatedAt=1681883865828",
  //     "course_link":"https://tally.so/r/wvrq48",
  //     "course_desc":"Start with Basic Python until advanced Visualization Concepts with Power Bi to become a Data Science Expert",
  //     "course_category":'Programming',
  //     "course_price":'Coming Soon',
  //     "course_status":['weekend live'],
  //     course_details:["60 Hrs of LIVE Online Training",
  //     "Concepts Explained in Kannada",
  //     "Completion Certificate",
  //     "Build Interactive Projects",
  //     "Doubt Clarification",
  //     "Interview Preparation",
  //     "Career Assistance",
  //   ],
  // },
  //   {
  //     "id":9,
  //     "course_name":'Interview Preparation',
  //     "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/Technical11.webp?updatedAt=1681883917548",
  //     "course_link":"https://tally.so/r/31kGML",
  //     "course_desc":"Start Cracking Technical Interviews like a Pro with this Basic to Advanced Interview Prep Course",
  //     "course_category":'Technical',
  //     "course_price":'Coming Soon',
  //     "course_status":['weekend live'],
  //     course_details:["04:00 PM to 06:00 PM (Sat-Sun)",
  //     "Concepts Explained in Kannada",
  //     "Completion Certificate",
  //     "Live Doubt Clarification",
  //     "Resume Building",
  //     "Mock Interviews",
  //     "Tech Profile",
  //   ],
  // },
      ]



//   #function starts from here    

    const [status, setStatus] = useState("Working Professionals")
    const [courseOutput, setCourseOutput] = useState(Courses)

    useEffect(() => {

        if(status===''){
          setCourseOutput(Courses)
        }
        else{
          setCourseOutput(Courses.filter((item)=>item.course_status.includes(status)))
        }
    }, [status])

    console.log(status.includes('live'))



  return (
    <div className="w-full h-full py-5 px-4 md:px-14 xl:px-18 2xl:px-20 space-y-8" id="courses" >
    <div className='w-full flex flex-col items-start space-y-3 2xl:space-y-4' id="section1">
    <div className='px-0 sm:px-0 w-full flex flex-col items-start space-y-3 2xl:space-y-5'>
    <div className='flex items-center space-x-3'><div className='bg-[#FF0049] p-[0.15rem] h-[0.2rem] w-[0.2rem] rounded-xl'></div> <div className='bg-[#FF0049] p-[0.15rem]   w-[0.1rem] rounded-xl'></div> <p className='font-bold text-gray-400 tracking-[0.3em] text-xs '>Learn New Skills</p> <div className='bg-[#FF0049]  p-[0.15rem] h-[0.2rem] w-[0.2rem] rounded-xl'></div> <div className='bg-[#FF0049]  p-[0.15rem] h-[0.2rem] w-[0.2rem] rounded-xl'></div> </div>

    <h1 className='font-poppins tracking-tight sm:text-5xl text-4xl 2xl:text-5xl whitespace-nowrap text-gray-700  mb-4'> <span className='font-bold'>Popular </span>Courses</h1>
    {/* <p className="max-w-[800px] text-left font-regular text-gray-700 text-base xl:text-lg 2xl:text-xl">IT skills to boost your skills and improve your resume. Learn from Kannadigas working at top MNCs with 10+ years experience</p> */}
    </div>
    <div className=" 2xl:pr-12 w-full flex justify-between items-center">
      <div className ='flex my-5'>
      <button onClick={()=>setStatus('Working Professionals')} className={` 2xl:text-base hover:bg-[#FF0049] ${status.includes('Working Professionals')?"bg-[#FF0049] text-white":"bg-gray-300"} hover:text-white px-3 py-2 2xl:px-5 2xl:py-3 rounded-sm rounded-tl-md text-sm`}>Working Professionals</button>
      {/* <button onClick={()=>setStatus('Non-IT Professional')} className={` 2xl:text-base hover:bg-[#FF0049] ${status.includes('Non-IT Professionals')?"bg-[#FF0049] text-white":"bg-gray-300"} hover:text-white px-3 py-2 2xl:px-5 2xl:py-3 rounded-sm sm:rounded-tl-md text-sm`} >Non-IT Professionals</button> */}
      <button onClick={()=>setStatus('Freshers')} className={` 2xl:text-base hover:bg-[#FF0049] ${status.includes('Freshers')?"bg-[#FF0049] text-white":"bg-gray-300"} hover:text-white px-3 py-2 2xl:px-5 2xl:py-3 rounded-sm sm:rounded-tr-md text-sm`} >Freshers</button>
      <button onClick={()=>setStatus('Students')} className={` 2xl:text-base hover:bg-[#FF0049] ${status.includes('Students')?"bg-[#FF0049] text-white":"bg-gray-300"} hover:text-white px-3 py-2 2xl:px-5 2xl:py-3 rounded-sm sm:rounded-tr-md text-sm`} >Students</button>
      </div>
      <a href="#section1 ">
       {/* <a href="https://trainings.microdegree.work/learn">  */}
      <button className=" 2xl:text-base hidden  h-fit  bg-[#151595] text-white text-sm rounded-md px-3 py-2 ">See All Courses</button>
      </a>
      </div>
    </div>
    <div className="flex justify-center sm:justify-start  items-center w-full h-full  md:space-x-8 flex-wrap sm:flex-nowrap md:space-y-0 space-y-14 overflow-x-scroll scrollbar-hide ">

      {
        courseOutput.map(item=>(
          <CourseCard key={item.id} desc={item.course_desc} course_details={item.course_details} courseLink={item.course_link} title={item.course_name} category={item.course_category} cardImg={item.course_img} price={item.course_price} ></CourseCard>
        ))
      }
    </div>
    <div className='py-2 hidden sm:block'>
    <a href="#section1">
    {/* <a href="https://trainings.microdegree.work/learn">  */}
      {/* <button className="h-fit sm:hidden bg-[#151595] text-white text-sm rounded-md px-6 py-3 ">See All Courses</button> */}
      </a></div>
      <div className='sm:hidden py-2'>
    <a href="#section1">
    {/* <a href="https://trainings.microdegree.work/learn">  */}
      {/* <button className="h-fit w-full sm:hidden bg-[#151595] text-white text-lg rounded-md px-6 py-3 shadow-md shadow-blue-900/50">See All Courses</button> */}
      </a></div>
  </div>


  )
}

export default PopularCourses


// import React from 'react'
// import { useEffect } from "react";
// import { useState } from 'react';
// import CourseCard from "./CourseCard";
// function PopularCourses() {


//     // json starts from here 

//     const Courses = [
//         {
//           id:1,
//           "course_img": "https://ik.imagekit.io/MicroDegree/Website/Course_Images/AWSDevOpsCertification.png?updatedAt=1681883569965",
//           "course_category":'Cloud Computing',
//           "course_name":'AWS & DevOps Certification',
//           "course_desc":'Master AWS & DevOps from Cloud industry experts to become a solid Computing Professional',
//           "course_link":'https://courses.microdegree.work/courses/aws-developer-certification-live',
//           "course_status":['daily live'],
//           course_details:["60 Hrs of LIVE Online Training",
//           "Concepts Explained in Kannada",
//           "Completion Certificate",
//           "Build Interactive Projects",
//           "Doubt Clarification",
//           "Interview Preparation",
//           "Career Assistance",
//         ],
//         },
//         {
//           id:2,
//           "course_img": "https://ik.imagekit.io/MicroDegree/Website/Course_Images/powerBi11.webp?updatedAt=1681883624607",
//           "course_category":'Data Science',
//           "course_name":'Power BI Certification',
//           "course_desc":"Master Business Intelligence with Power BI & Tableau from Industry Experts to become a solid Professional in Data Visualization & Reporting",
//           "course_link":'https://courses.microdegree.work/courses/power-bi-tableau-certification',
//           "course_status":['daily live'],
//           course_details:["65 Hrs of LIVE Online Training",
//           "Concepts Explained in Kannada",
//           "Completion Certificate",
//           "Build Interactive Projects",
//           "Doubt Clarification",
//           "Interview Preparation",
//           "Career Assistance",
//         ],
//         },

//         {
//           id:3,
//           "course_img": require('../assets/Course Images/Python11.webp'),
//           "course_category":'Data Science',
//           "course_name":'Python Certification',
//           "course_desc":"Start with Basic Python until advanced Visualization Concepts with Power Bi to become a Data Science Expert",
//           "course_link":'https://courses.microdegree.work/courses/python',
//           "course_status":['recorded'],
//           course_details:["Course starts from complete Basics",
//           "No prior coding knowledge required",
//           "Get doubts cleared instantly ",
//           "Quizzes & Mini Projects ",
//           "Unlimited access",
//           "Interview Preparation",
//           "Career Assistance",
//         ],
//         },
//         {
//           "id":4,
//           "course_name":'Full Stack Web Development',
//           "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/webDev11.webp?updatedAt=1681883678095",
//           "course_link":"https://courses.microdegree.work/courses/full-stack-web-development-certification",
//           "course_desc":"Start with Basic HTML untill advanced Server Side Concepts with NodeJs & MongoDB to become a Expert MERN Stack Developer",
//           "course_category":'Programming',
//           "course_status":['weekend live'],
//           course_details:["60 Hrs of LIVE Online Training",
//           "Concepts Explained in Kannada",
//           "Completion Certificate",
//           "Build Interactive Projects",
//           "Doubt Clarification",
//           "Interview Preparation",
//           "Career Assistance",
//         ],},

//         {
//           id:5,
//           "course_img": "https://ik.imagekit.io/MicroDegree/Website/Course_Images/MySQL.png?updatedAt=1681883736088",
//           "course_category":'DataBase',
//           "course_name":'MySQL Certification',
//           "course_desc": "Learn Python from scratch and build interactve projects. Kick-start your journey into the world of Data Science and Machine Learning here.",
//           "course_status":['recorded'],
//           "course_link":'https://courses.microdegree.work/courses/mysql',
//           course_details:["5 Hours of Course Content",
//           "No prior coding knowledge required",
//           "Get doubts cleared instantly ",
//           "Quizzes & Mini Projects ",
//           "Unlimited access",
//           "Telegram Developer Community",
//           "Career Assistance",
//         ],
//         },

//         {
//           "id":6,
//           "course_name":'Automation Testing - Java',
//           "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/Automation_java.png?updatedAt=1681883792202",
//           "course_link":"https://tally.so/r/3ND8Lb",
//           "course_desc":"Master Automation Testing and learn most in-demand skills like Core Java, Selenium & Automation testing to become a solid QA Professional",
//           "course_category":'Programming',
//           "course_price":'Coming Soon',
//           "course_status":['daily live'],
//           course_details:["60 Hrs of LIVE Online Training",
//           "Concepts Explained in Kannada",
//           "Completion Certificate",
//           "Build Interactive Projects",
//           "Doubt Clarification",
//           "Interview Preparation",
//           "Career Assistance",
//         ],
//       },
//       {
//         "id":7,
//         "course_name":'ReactJS',
//         "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/ReactJS.png?updatedAt=1681883830418",
//         "course_link":"https://courses.microdegree.work/courses/react",
//         "course_desc":"Learn React from scratch, build real-world project and get ready to be a solid Frontend Developer",
//         "course_category":'Programming',
//         "course_status":['recorded'],
//         course_details:["60 Hrs of LIVE Online Training",
//         "Concepts Explained in Kannada",
//         "Completion Certificate",
//         "Build Interactive Projects",
//         "Doubt Clarification",
//         "Interview Preparation",
//         "Career Assistance",
//       ],
//     },
//     {
//       "id":8,
//       "course_name":'Data Science Weekend Live',
//       "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/FullStackData11.webp?updatedAt=1681883865828",
//       "course_link":"https://tally.so/r/wvrq48",
//       "course_desc":"Start with Basic Python until advanced Visualization Concepts with Power Bi to become a Data Science Expert",
//       "course_category":'Programming',
//       "course_price":'Coming Soon',
//       "course_status":['weekend live'],
//       course_details:["60 Hrs of LIVE Online Training",
//       "Concepts Explained in Kannada",
//       "Completion Certificate",
//       "Build Interactive Projects",
//       "Doubt Clarification",
//       "Interview Preparation",
//       "Career Assistance",
//     ],
//   },
//     {
//       "id":9,
//       "course_name":'Interview Preparation',
//       "course_img":"https://ik.imagekit.io/MicroDegree/Website/Course_Images/Technical11.webp?updatedAt=1681883917548",
//       "course_link":"https://tally.so/r/31kGML",
//       "course_desc":"Start Cracking Technical Interviews like a Pro with this Basic to Advanced Interview Prep Course",
//       "course_category":'Technical',
//       "course_price":'Coming Soon',
//       "course_status":['weekend live'],
//       course_details:["04:00 PM to 06:00 PM (Sat-Sun)",
//       "Concepts Explained in Kannada",
//       "Completion Certificate",
//       "Live Doubt Clarification",
//       "Resume Building",
//       "Mock Interviews",
//       "Tech Profile",
//     ],
//   },
//       ]



// //   #function starts from here    

//     const [status, setStatus] = useState("daily live")
//     const [courseOutput, setCourseOutput] = useState(Courses)

//     useEffect(() => {

//         if(status===''){
//           setCourseOutput(Courses)
//         }
//         else{
//           setCourseOutput(Courses.filter((item)=>item.course_status.includes(status)))
//         }
//     }, [status])

//     console.log(status.includes('live'))



//   return (
//     <div className="w-full h-full py-5 px-10 md:px-14 xl:px-18 2xl:px-20   space-y-8" id="courses">
//     <div className='w-full flex flex-col items-start space-y-3 2xl:space-y-4 '>
//     <div className='px-5 sm:px-0 w-full flex flex-col items-start space-y-3 2xl:space-y-5'>
//     <div className='flex items-center space-x-3'><div className='bg-[#FF0049]   p-[0.15rem] h-[0.2rem] w-[0.2rem] rounded-xl'></div> <div className='bg-[#FF0049] p-[0.15rem]   w-[0.1rem] rounded-xl'></div> <p className='font-bold text-gray-400 tracking-[0.3em] text-xs '>Learn New Skills</p> <div className='bg-[#FF0049]  p-[0.15rem] h-[0.2rem] w-[0.2rem] rounded-xl'></div> <div className='bg-[#FF0049]  p-[0.15rem] h-[0.2rem] w-[0.2rem] rounded-xl'></div> </div>

//     <h1 className='sm:text-5xl text-4xl 2xl:text-6xl whitespace-nowrap text-gray-700  mb-4'> <span className='font-[600]'>Popular </span>Courses</h1>
//     <p className="max-w-[800px] text-left font-regular text-gray-700 text-base xl:text-lg 2xl:text-xl">IT skills to boost your skills and improve your resume. Learn from Kannadigas working at top MNCs with 10+ years experience</p>
//     </div>
//     <div className=" 2xl:pr-12 w-full flex justify-between items-center ">
//       <div className ='flex m-auto my-5 sm:m-0 '>
//       <button onClick={()=>setStatus('daily live')} className={` 2xl:text-base hover:bg-[#FF0049] ${status.includes('daily live')?"bg-[#FF0049] text-white":"bg-gray-300"} hover:text-white px-3 py-2 2xl:px-5 2xl:py-3 rounded-sm rounded-tl-md text-sm`}>Daily Live</button>
//       <button onClick={()=>setStatus('weekend live')} className={` 2xl:text-base hover:bg-[#FF0049] ${status.includes('weekend live')?"bg-[#FF0049] text-white":"bg-gray-300"} hover:text-white px-3 py-2 2xl:px-5 2xl:py-3 rounded-sm text-sm`} >Weekend Live</button>
//       <button onClick={()=>setStatus('recorded')} className={` 2xl:text-base hover:bg-[#FF0049] ${status.includes('recorded')?"bg-[#FF0049] text-white":"bg-gray-300"} hover:text-white px-3 py-2 2xl:px-5 2xl:py-3 rounded-sm sm:rounded-tr-md text-sm`} >Recorded</button>
//       </div>
//       <a href="https://courses.microdegree.work/pages/premium-courses">
//       <button className=" 2xl:text-base hidden sm:block h-fit  bg-purple-700 text-white text-sm rounded-md px-3 py-2 ">See All Courses</button>
//       </a>
//       </div>
//     </div>
//     <div className="px-3 md:px-0 flex justify-center sm:justify-start  items-center w-full h-full  md:space-x-8 flex-wrap sm:flex-nowrap md:space-y-0 space-y-14 overflow-x-scroll scrollbar-hide ">

//       {
//         courseOutput.map(item=>(
//           <CourseCard key={item.id} desc={item.course_desc} course_details={item.course_details} courseLink={item.course_link} title={item.course_name} category={item.course_category} cardImg={item.course_img} price={item.course_price} ></CourseCard>
//         ))
//       }
//     </div>
//     <div className='py-2'>
//     <a href="https://courses.microdegree.work/pages/premium-courses">
//       <button className="h-fit sm:hidden bg-purple-700 text-white text-sm rounded-md px-6 py-3 ">See All Courses</button>
//       </a></div>
//   </div>
//   )
// }

// export default PopularCourses