import React from 'react'
import imageoverlay from '../assets/homeWebp/heroSection/overlayimg.webp'
import playicon from '../assets/homeWebp/heroSection/playicon.webp'
function VideoCard({handleVideo, vidthumb, title}) {
  return (
    <div id="video" onClick={handleVideo} className=' cursor-pointer rounded-md  w-[11rem] h-[8rem] sm:min-w-[11rem] sm:max-w-[10rem] 2xl:w-50 2xl:h-40 xl:w-50 xl:h-36 md:min-w-[48%] md:h-[250px] sm:h-[7.5rem]'>
      
      <div className='h-3/4 relative  w-full'>
      <img className='w-full rounded-md h-full shrink-0 object-fit' src={vidthumb} alt="" loading="lazy"/>
      {/* <img src={imageoverlay} className='absolute w-full h-full top-0' alt="" loading="lazy"/>
      <img src={playicon} className='absolute top-10 left-20 w-1/6' alt="" loading="lazy"/> */}
      </div>
    </div>
  )
}

export default VideoCard