import React, { useState, lazy, Suspense } from "react";
import Hero from "../components/Hero";
import PopularCourses from "../components/PopularCourses";
import Counter from "../components/Counter";
// import EmpoweringColleges from "../components/EmpoweringColleges";
// import Certificates from "../components/Certificates";
// import TrainersFeedback from "../components/TrainersFeedback";
// import Team from "../components/Team";
// import Reviews from "../components/Reviews";
// import CourseHighlights from "../components/CourseHighlights";
// import Scholarship from "../components/Scholarship";
// import DemoSessionCard from "../components/DemoSessionCard";
// Lazy loading for components
const Modal = lazy(() => import('../components/Modal'));
const Learners = lazy(() => import('../components/Learners'));
const Placements = lazy(() => import('../components/Placements'));
const VideoModal = lazy(() => import('../components/VideoModal'));
const HomeForm2 = lazy(() => import('../components/HomeForm2'));
const JoinMicroDegree = lazy(() => import('../components/JoinMicroDegree'));
const IndustryTrainers = lazy(() => import('../components/IndustryTrainers'));
const HomeForm1 = lazy(() => import('../components/HomeForm1'));
const NewsMedia = lazy(() => import('../components/News&Media'));
const Recognisation = lazy(() => import('../components/RecognisationSection'));
const EmpoweringColleges = lazy(() => import('../components/EmpoweringColleges'));
const TrainersFeedback  = lazy(() => import('../components/TrainersFeedback'));
const Team = lazy(() => import('../components/Team'));
const Reviews = lazy(() => import('../components/Reviews'));
const CourseHighlights  = lazy(() => import('../components/CourseHighlights'));
const Scholarship  = lazy(() => import('../components/Scholarship'));
const DemoSessionCard  = lazy(() => import('../components/DemoSessionCard'));
const Certificates  = lazy(() => import('../components/Certificates'));
const YTFullCourse = lazy(() => import('../components/YTFullCourse'));
const Devopsforkannadigas = lazy(() => import('../components/Devopsforkannadigas'));
const Pythonforkannadigas = lazy(() => import('../components/Pythonforkannadigas'));

function Home({ handleAlert }) {
  const [videoModal, setVideoModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [videoOutput, setVideoOutput] = useState();
  const [learnerVideo, setLearnerVideo] = useState('');
  const [learnerModal, setLearnerModal] = useState(false);

  

  return (
    <div className="relative overflow-x-hidden">
      {/* main home section starts from here */}
      <div className="main_container relative">
      {viewModal && <Modal handleAlert={handleAlert} viewModal={viewModal} setViewModal={setViewModal}></Modal>}
          {videoModal && <VideoModal vidLink={videoOutput.link} vidTitle={videoOutput.title} setvideoModal={setVideoModal} videoModal={videoModal}></VideoModal>}
          {learnerModal && <VideoModal setvideoModal={setLearnerModal} videoModal={learnerModal} vidLink={learnerVideo}></VideoModal>}
      <section id="hero"><Hero viewModal={viewModal} setViewModal={setViewModal} videoModal={videoModal} setvideoModal={setVideoModal} videoOutput={videoOutput} setVideoOutput={setVideoOutput}></Hero></section>
          <PopularCourses></PopularCourses>
          
          <Counter></Counter>
          <CourseHighlights></CourseHighlights>
          <Devopsforkannadigas></Devopsforkannadigas>
          <Pythonforkannadigas></Pythonforkannadigas>
          <div className="my-10">
         </div>
          <YTFullCourse learnerVideo={learnerVideo} setLearnerModal={setLearnerModal} learnerModal={learnerModal} setLearnerVideo={setLearnerVideo}></YTFullCourse>
          <Placements></Placements>
        <Suspense fallback={<div>Loading...</div>}>
        <Learners learnerVideo={learnerVideo} setLearnerModal={setLearnerModal} learnerModal={learnerModal} setLearnerVideo={setLearnerVideo}></Learners>
        
        <EmpoweringColleges></EmpoweringColleges>

        <Certificates></Certificates>
        <TrainersFeedback></TrainersFeedback>
        <Reviews></Reviews>
        <IndustryTrainers></IndustryTrainers>
        <DemoSessionCard></DemoSessionCard>
          <Recognisation></Recognisation>
          <JoinMicroDegree></JoinMicroDegree>
          <Scholarship></Scholarship>
          <HomeForm1></HomeForm1>
          <NewsMedia></NewsMedia>
          <Team></Team>
          <HomeForm2></HomeForm2>
        </Suspense>
      </div>
    </div>
  );
}

export default Home;